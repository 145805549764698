import { FC, useEffect, useState } from "react";
import {Box, Paper, Select, Text} from "@mantine/core";

import {formatDate} from "@/utils/helpers";

import ModalConfirm from "@/ui/ModalConfirm/ModalConfirm";

import { useStyles } from './styles';
import { IShowUserDetailInfo } from "./types";
import UserBoxTitle from "./UserBoxTitle";
import UserColumnWrapper from "./UserColumnWrapper";

import { useActions, useTypedSelector } from "@/store/hooks";

const UserDetailBody: FC<IShowUserDetailInfo> = ({ jsonData }) => {
    const { getChangeLegalEntity, getLegalEntities } = useActions();
    const { classes } = useStyles();
    const { user } = useTypedSelector(state => state.auth);
    const { userDetailId, legalEntities} = useTypedSelector(state => state.users);
    const roleIN = !!jsonData ? !!jsonData.role ? !!jsonData?.role ? jsonData?.role : '' : '' : '';
    const userRoleDCS = roleIN === 'ROLE_DISTRIBUTOR' || roleIN === 'ROLE_CERTIFICATE_MANAGER' || roleIN === 'ROLE_SCHOOL_ADMIN'
    const userRoleAMDCSU = roleIN === 'ROLE_ADMIN' || roleIN === 'ROLE_MANAGER' || roleIN === 'ROLE_DISTRIBUTOR' || roleIN === 'ROLE_CERTIFICATE_MANAGER' || roleIN === 'ROLE_SCHOOL_ADMIN' || roleIN === 'ROLE_USER'
    const [selectedFields, setSelectedFields] = useState<string[]>([]);
    const [modalChangeOrganization, setModalChangeOrganization] = useState<boolean>(false);
    const legalEntitiesData = legalEntities.map((result) => ({ value: result?.id?.toString(), label: result.name })) as { value: string; label: string }[];
    const [legalEntitiesDataChange, setLegalEntitiesDataChange] = useState<string>('');
    const externalOrganizationFull = jsonData.organization.map(e => e.external ? e.name : '');
    const externalOrganization = externalOrganizationFull.filter(e => e !== '');
    const quantOrg = externalOrganization.length;
    const internalOrganizationFull = jsonData.organization.map(e => !e.external ? e.name : '');
    const internalOrganization = internalOrganizationFull.filter(e => e !== '');
    const quantInternalOrg = internalOrganization.length ?? 1;
    const phones = jsonData.phone?.map(phone => typeof phone === 'string' ? phone : phone.value) ?? [];

    const onClickHide = (title: string) => {
        setSelectedFields((prevState) => {
            const newState = [...prevState];
            newState.splice(prevState.findIndex(item => item === title), 1);
            return newState;
        })
    };

    useEffect(() => {
      if (modalChangeOrganization) {
        getLegalEntities();
      }
    }, [modalChangeOrganization]);

    return (
        <>
            <Paper className={classes.Paper}>
                <UserColumnWrapper >
                    <Box className={classes.ColumnBox} mx={'auto'}>
                        <UserBoxTitle title="UUID" hideAll />
                        <Text className={classes.BoxText}>
                            { jsonData.uuid || '-' }
                        </Text>
                    </Box>
                    <Box className={classes.ColumnBox} mx={'auto'}>
                        <UserBoxTitle title="ФИО" hideAll />
                        <Text className={classes.BoxText}>
                            { jsonData.name || '-'  }
                        </Text>
                    </Box>
                    <Box className={classes.ColumnBox} mx={'auto'}>
                        <UserBoxTitle title="Email" hideAll />
                        <Text className={classes.BoxText}>
                            { jsonData.email || '-' }
                        </Text>
                    </Box>
                    { phones.map((phone, index) =>
                        <Box key={index} className={classes.ColumnBox} mx={'auto'}>
                            <UserBoxTitle title={phones.length > 1 ? `Телефон # ${index + 1}` : 'Телефон'} hideAll />
                            <Text className={classes.BoxText}>{ phone || '-' }</Text>
                        </Box>
                    ) }
                </UserColumnWrapper>
                <UserColumnWrapper>
                    {
                        userRoleAMDCSU &&
                        ( !internalOrganization.length ?
                            (<Box className={classes.ColumnBox} mx={'auto'}>
                                <UserBoxTitle
                                    title={'Юридическое лицо'}
                                    hideEdit={selectedFields.includes("legalEnt") || (user?.role.code !== 'ROLE_ADMIN')}
                                    onClickEdit={() => setModalChangeOrganization(() => true)}
                                />
                                <Text className={classes.BoxText}>
                                    { internalOrganization?.[0] || '-' }
                                </Text>
                            </Box>) : internalOrganization.map((value, index) =>
                                  <Box key={index} className={classes.ColumnBox} mx={'auto'}>
                                      <UserBoxTitle
                                          title={(!!quantInternalOrg && quantInternalOrg === 1) ? 'Юридическое лицо' : `Юридическое лицо # ${index + 1}`}
                                          hideEdit={selectedFields.includes("legalEnt") || (user?.role.code !== 'ROLE_ADMIN')}
                                          onClickEdit={() => setModalChangeOrganization(() => true)}
                                      />
                                      <Text className={classes.BoxText}>
                                          { value || '-' }
                                      </Text>
                                  </Box>
                            ))
                    }
                    { userRoleDCS && externalOrganization.map((value, index) =>
                        <Box key={index} className={classes.ColumnBox} mx={'auto'}>
                          <UserBoxTitle title={(!!quantOrg && quantOrg === 1) ? 'Название организации' : `Название организации # ${index + 1}`} hideAll />
                          <Text className={classes.BoxText}>
                            { value || '-' }
                          </Text>
                        </Box>
                      )
                    }
                    <Box className={classes.ColumnBox} mx={'auto'}>
                        <UserBoxTitle title="Регион" hideAll />
                        <Text className={classes.BoxText}>
                            { jsonData?.region?.name || '-' }
                        </Text>
                    </Box>
                </UserColumnWrapper>
                <UserColumnWrapper>
                    <Box className={classes.ColumnBox} mx={'auto'}>
                        <UserBoxTitle title="Дата создания" hideAll />
                        <Text className={classes.BoxText}>
                            { formatDate(jsonData.createdAt) }
                        </Text>
                    </Box>
                    <Box className={classes.ColumnBox} mx={'auto'}>
                        <UserBoxTitle title="Дата обновления" hideAll />
                        <Text className={classes.BoxText}>
                            { formatDate(jsonData.updatedAt) }
                        </Text>
                    </Box>
                </UserColumnWrapper>
            </Paper>

            <ModalConfirm
                opened={modalChangeOrganization}
                onClose={() => {
                    setModalChangeOrganization(false);
                    onClickHide('Юридическое лицо');
                }}
                title="Сменить юридическое лицо?"
                text="Юридическое лицо менеджера будет изменено. Выберите организацию из списка"
                accept="Да, изменить"
                decline="Нет, отменить"
                onAccept={() => {
                    if (userDetailId) {
                        getChangeLegalEntity({
                            id: userDetailId,
                            organization: legalEntitiesDataChange
                        });
                    }
                }}
            >
              <>
                <Text style={{ marginTop: 24, fontSize: 16 }}>Выберите юридическое лицо</Text>
                <Select
                  mt={16}
                  mb={32}
                  placeholder="Юридическое лицо"
                  value={legalEntitiesDataChange}
                  data={legalEntitiesData}
                  onChange={(value) => {
                      setLegalEntitiesDataChange(value ?? '')
                  }}
                />
              </>
            </ModalConfirm>
        </>
    )
}

export default UserDetailBody;
