import {RoleEnum} from "@/types/enum/RoleEnum";
import {StatusEnum} from "@/types/enum/StatusEnum";

import { FC, useEffect, useState } from 'react';
import {useParams} from "react-router-dom";
import {Box, Divider, Group, Paper, UnstyledButton} from '@mantine/core';

import { AppPath } from '@/routes/routes-enum';

import DetailHeaderTitle from '@/ui/DetailHeader/DetailHeaderTitle';
import PageHeader from '@/ui/PageHeader/PageHeader';

import CopySendUserDetail from './UserDetail/CopySendUserDetail';
import RightButtonDetail from './UserDetail/RighButtonDetail';
import UserDetailBody from './UserDetail/UserDetailBody';

import {useStyles} from "@/pages/Packs/PacksDetail/styles";
import BlockUserModal from "@/pages/Users/Modal/BlockUserModal";
import ChangeRoleModal from "@/pages/Users/Modal/ChangeRoleModal";
import UnblockUserModal from "@/pages/Users/Modal/UnblockUserModal";
import UserDetailPacks from "@/pages/Users/UserDetail/UserDetailPacks";
import { useActions, useTypedSelector } from '@/store/hooks';

const UserDetail: FC = () => {
  const { classes, cx } = useStyles();
  const { getUserDetail, setUserDetailId, setMutateUsers } = useActions();
  const { userDetail,
    isUserDetailLoading,
    loadingUsers,
    isLegalEntityLoading,
    isChangeLegalEntityLoading,
    isChangeLegalEntitySuccess,
    isCheckEmailLoading,
    isPutUsersLoading,
    singleUser,
    mutateUsers
  } = useTypedSelector(state => state.users);
  const [showUserDetailTable, setShowUserDetailTable] = useState(false);
  const [showUserInfo, setShowUserInfo] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    if (
      loadingUsers ||
      isUserDetailLoading ||
      isLegalEntityLoading ||
      isChangeLegalEntityLoading ||
      isCheckEmailLoading ||
      isPutUsersLoading
    ) return;

    if (id) {
      getUserDetail({ id: Number(id) });
    }

    setMutateUsers(false)
  }, [id, mutateUsers]);

  useEffect(() => {
    if (id) {
      setUserDetailId(parseInt(id));
    }

    return () => {
      setUserDetailId(null)
    }
  }, [id]);

    useEffect(() => {
        if (isChangeLegalEntityLoading) return;

        if (isChangeLegalEntitySuccess) {
            if (!mutateUsers) {
                setMutateUsers(true);
            }
        }
    }, [isChangeLegalEntityLoading]);

  return (
    <>
      <ChangeRoleModal modalTitle={"Изменить роль"} selectedID={[Number(id)]} />
      <BlockUserModal modalTitle={singleUser ? "Заблокировать пользователя?" : "Заблокировать пользователей?"} blockButton={"Да, заблокировать"} reverseButton={"Нет, отменить"} single={singleUser} selectedID={[Number(id)]} />
      <UnblockUserModal modalTitle={singleUser ? "Разблокировать пользователя?" : "Разблокировать пользователей?"} unblockButton={"Да, разблокировать"} reverseButton={"Нет, отменить"} single={singleUser} selectedID={[Number(id)]} />

      <Box>
        <PageHeader title="К списку пользователей" backLink={AppPath.users} />
      </Box>
      { userDetail &&
        <Paper mb={16} style={{display: 'block'}}>
          <Group noWrap spacing={32} px={0}>
            <UnstyledButton className={cx(classes.root, {[classes.active]: showUserInfo})} onClick={() => {setShowUserInfo(true); setShowUserDetailTable(false) }}>
              Информация
            </UnstyledButton>
            <UnstyledButton className={cx(classes.root, {[classes.active]: showUserDetailTable})} onClick={() => { setShowUserInfo(false); setShowUserDetailTable(true) }}>
              Список комплектов
            </UnstyledButton>
          </Group>
          <Divider mb={24} mx={-24}/>
          <Box style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
            <Group position="apart" h={52} sx={{alignContent: 'center'}}>
              <DetailHeaderTitle title={`ID: ${id}`} subTitle={RoleEnum[userDetail.role]} status={userDetail.status} detail={false} />
              <Group position="left" sx={{alignItems: 'baseline'}}>
                <RightButtonDetail status={userDetail.status} />
              </Group>
            </Group>
            { userDetail.status === StatusEnum.Inactive &&
              <Group position='left'>
                { (!isUserDetailLoading && showUserInfo) && <CopySendUserDetail status={userDetail.status} />}
              </Group> }
          </Box>
        </Paper>
      }
      { (userDetail && showUserInfo) && <UserDetailBody jsonData={userDetail} /> }
      { showUserDetailTable && <UserDetailPacks /> }
    </>
  );
};

export default UserDetail;
