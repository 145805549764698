import {StatusEnum} from "@/types/enum/StatusEnum";

import { FC, useEffect } from "react";
import { Button } from "@mantine/core";

import { useActions, useTypedSelector } from "@/store/hooks";

interface IRightButtonDetail {
    status?: number;
}

const RightButtonDetail: FC<IRightButtonDetail> = ({ status }) => {
    const { user } = useTypedSelector((store) => store.auth);
    const { userDetailId, isUserDetailLoading } = useTypedSelector((store) => store.users);
    const {
        setBlockUserModal,
        setUnblockUserModal,
        setChangeRoleModal,
        setSingleUser,
    } = useActions();
    const RoleAM = user?.role.code === 'ROLE_ADMIN' || user?.role.code === 'ROLE_MANAGER';

    useEffect(() => {
        setSingleUser(true);
    }, []);

    return (
        <>
            {RoleAM &&
                (status === StatusEnum.Blocked ?
                    <Button
                        loading={isUserDetailLoading}
                        disabled={userDetailId === user?.id}
                        variant={'outline'}
                        onClick={() => setUnblockUserModal(true)}
                    >Разблокировать</Button> :
                    <Button
                        loading={isUserDetailLoading}
                        disabled={userDetailId === user?.id}
                        variant={'outline'}
                        onClick={() => setBlockUserModal(true)}
                    >Заблокировать
                    </Button>)
            }
            <Button loading={isUserDetailLoading} disabled={status === StatusEnum.Blocked || (userDetailId === user?.id)} onClick={() => setChangeRoleModal(true)}>Изменить роль</Button>
        </>
    );
}


export default RightButtonDetail;
