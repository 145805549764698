import {GetPackDetailResponse} from '@/types';

import React, { FC, useEffect, useState } from 'react';
import {useParams} from "react-router-dom";
import {Box, Divider, Group, Indicator, Paper, UnstyledButton} from "@mantine/core";
import moment from "moment/moment";

import {formatDate, getStatusColor, getStatusLabel} from "@/utils/helpers";

import {AppPath} from "@/routes/routes-enum";

import DetailHeader from "@/ui/DetailHeader/DetailHeader";
import PageHeader from "@/ui/PageHeader/PageHeader";
import Table from "@/ui/Table/Table";
import TableControls from "@/ui/TableControls/TableControls";

import { ReactComponent as Infinity } from '@/assets/icons/Infinity.svg';
import ExportPacksConsist from "@/pages/Packs/Modal/ExportPacksConsist";
import DateChangeInput from "@/pages/Packs/PacksDetail/DateChangeInput";
import PacksDetailBody from "@/pages/Packs/PacksDetail/PacksDetailBody";
import RightButtonPacksDetail from "@/pages/Packs/PacksDetail/RightButtonPacksDetail";
import {useStyles} from "@/pages/Packs/PacksDetail/styles";
import getPeriod from "@/pages/Packs/utils/getPeriod";
import {useActions, useTypedSelector} from "@/store/hooks";
import {Licenses} from "@/store/licenses/types";
import {getUserDetail} from "@/store/users/actions";

const PacksDetail: FC = () => {
  const {
    getLicensesSearchSuggestions,
    setLicensesSearchQuery,
    setMutatePacksDetail,
    getPacksDetail,
    getLicenses,
    setLicensesPage,
    setLicensesSort,
    setSelectedLicenses,
    setTriggerTableChange,
    setPacksDetailId,
    setLicensesExportLink
  } = useActions();

  const { classes, cx } = useStyles();
  const { statusProducts } = useTypedSelector(state => state.products);
  const { id } = useParams();

  const {
    packsDetail,
    isPacksDetailError,
    triggerTableChange,
    loadingStatus,
    isLicensesTemplateLoading,
    isPackDeactivateLoading,
    isPacksLicensesLoading,
    isPacksDetailLoading,
    isPackUpdateLoading,
    isPacksCheckLoading,
    isPackEditLoading,
    isTemplateLoading,
    isPacksLoading,
    isPackExpiredDateLoading,
    isPackExpiredDateSuccess,
    mutatePacksDetail,
  } = useTypedSelector(store => store.packs);
  const { user } = useTypedSelector((store) => store.auth);
  const [obj, setObj] = useState<GetPackDetailResponse | null>(null);
  const [showPacksDetailTable, setShowPacksDetailTable] = useState(false);
  const [showPacksDetailInfo, setShowPacksDetailInfo] = useState(true);
  const { licenses, pagination, sort, filtersLicenses, search, statusLoading, selectedLicenses, licensesExportLink, initialLicensesSort } = useTypedSelector((store) => store.licenses);
  const { search: productSearch } = useTypedSelector((store) => store.products);
  const [modalExportOpened, setModalExportOpened] = useState<boolean>(false);
  const disabledPagination = statusLoading.loadingLicenses || statusLoading.loadingLicensesAssets || statusLoading.loadingLicensesSearch;
  const isSchoolAdmin = user?.role?.code === 'ROLE_SCHOOL_ADMIN';

    const columns = [
    { id: 'id', text: 'ID', width: 50 },
    { id: 'status', text: 'Статус', sortable: true, width: 104 },
    { id: 'externalCode', text: 'Номенклатура', width: 135 },
    { id: 'type', text: 'Тип продукта', sortable: true, width: 120, spoiler: true },
    { id: 'name', text: 'Название', sortable: true, width: 382 },
    { id: 'volume', text: 'Кол-во', width: 105 },
    { id: 'activated', text: 'Активировано', sortable: true, width: 157 },
    { id: 'author', text: 'Автор', sortable: true, width: 222, spoiler: true, spoilerClickable: true, style: {width: 222} },
    { id: 'createdAt', text: 'Дата создания', sortable: true, width: 150 },
    { id: 'expired', text: 'Дата окончания', sortable: true, width: 150, style: {width: 170} },
    { id: 'activatedAt', text: 'Дата активации', sortable: true, width: 150 },
  ];

  useEffect(() => {
    if (licensesExportLink) {
      const href = URL.createObjectURL(licensesExportLink);
      const link = document.createElement('a');
      let createDate = new Date().toLocaleString();
      link.href = href;
      link.setAttribute('download', `Список лицензий ${createDate}.xls`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      setLicensesExportLink(null);
    }
  }, [licensesExportLink]);

  const onGetLicense = () => {
    getLicenses({
      setId: Number(id),
      page: pagination.currentPage,
      sort: sort?.field,
      direction: sort?.direction,
      query: search.query,
      ...Object.fromEntries(
        Object.entries(filtersLicenses).map(([key, value]) => [
          key,
          Array.isArray(value) ? value.join(',') : value,
        ])
      ),
    });
  }

  const tableLicenses = licenses?.map(({ createdAt, expired, activatedAt, total, volume, status, ...item }) => ({
    volume: (
      Number(total) === 0 ? <Infinity mt={16} /> : volume
    ),
    status: (
      <Indicator color={getStatusColor(status)} position="middle-start" zIndex={0} size={8} px={10}>
        { getStatusLabel(status) }
      </Indicator>
    ),
    createdAt: (
      formatDate(createdAt)
    ),
    expired: (
      isSchoolAdmin ? formatDate(expired) : <DateChangeInput date={expired} id={item.id} />
    ),
    activatedAt: (
      formatDate(activatedAt)
    ),
    ...item,
  }));

  const onSelectLicenses = (selection: number[]) => {
    if (!licenses) return;
    if (selection.length > selectedLicenses.length) {
      const targetId = selection.find(val => !selectedLicenses.find(userx => userx.id === val));
      setSelectedLicenses([...selectedLicenses, licenses.find(userx => userx.id === targetId) as Licenses]);
    } else if (selection.length < selectedLicenses.length) {
      const targetIdIndex = selectedLicenses.findIndex(userx => !selection.find(val => userx.id === val));
      const newLicenses = [...selectedLicenses]
      newLicenses.splice(targetIdIndex, 1);
      setSelectedLicenses(newLicenses);
    }
  }

  const uncheckAll = () => {
    setSelectedLicenses([]);
    setTriggerTableChange(!triggerTableChange);
  }

  useEffect(() => {
    if (statusProducts.isProductsLoading ||
      statusProducts.isProductsLoadingAssets ||
      statusProducts.isProductsLoadingSearch ||
      statusLoading.loadingLicenses ||
      statusLoading.loadingLicensesAssets ||
      statusLoading.loadingLicensesSearch ||
      isPacksDetailLoading ||
      !showPacksDetailTable
    ) return;

    onGetLicense();
  }, [pagination.currentPage, sort?.field, sort?.direction, filtersLicenses, search.query, productSearch.query, showPacksDetailTable]);


  useEffect(() => {
    if (id) {
      setPacksDetailId(parseInt(id));
    }

    return () => {
      setPacksDetailId(null)
    }
  }, [id]);

  useEffect(() => {
    if (loadingStatus.loading ||
      loadingStatus.loadingAssets ||
      loadingStatus.loadingSearch ||
      isLicensesTemplateLoading ||
      isPackDeactivateLoading ||
      isPacksLicensesLoading ||
      isPacksDetailLoading ||
      isPackUpdateLoading ||
      isPacksCheckLoading ||
      isPackEditLoading ||
      isTemplateLoading ||
      isPacksLoading
    ) return;

    if (id) {
      getPacksDetail({ id: Number(id) })
    }

    setMutatePacksDetail(false)
  }, [id, mutatePacksDetail]);

  useEffect(() => {
    if (isPacksDetailLoading) return;

    if (!isPacksDetailError) {
      setObj(() => packsDetail);

      if (packsDetail?.userId) {
        getUserDetail({ id: packsDetail?.userId })
      }
    }
  }, [isPacksDetailLoading, packsDetail]);

  useEffect(() => {
    if (isPackExpiredDateLoading || showPacksDetailTable) return;

    if (isPackExpiredDateSuccess) {
      setMutatePacksDetail(true);
    }
  }, [isPackExpiredDateLoading])

  return (
    <>
      <ExportPacksConsist modalExportOpened={modalExportOpened} setModalExportOpened={setModalExportOpened}/>
      <Box>
        <PageHeader title="К списку комплектов" backLink={AppPath.packs} />
      </Box>
      { obj &&
        <Paper mb={16} style={{ display: 'block' }}>
          <>
            <Group noWrap spacing={32} px={0}>
              <UnstyledButton className={cx(classes.root, { [classes.active]: showPacksDetailInfo })} onClick={() => { setShowPacksDetailTable(() => false); setShowPacksDetailInfo(() => true); }}>
                Информация о комплекте
              </UnstyledButton>
              <UnstyledButton className={cx(classes.root, { [classes.active]: showPacksDetailTable })} onClick={() => {setShowPacksDetailInfo(() => false); setShowPacksDetailTable(() => true); }}>
                Состав комплекта
              </UnstyledButton>
            </Group>
            <Divider mb={24} mx={-24} />
            <DetailHeader
              title={`ID: ${id}`}
              status={obj?.status}
              uncheckAll={uncheckAll}
              isCheck={selectedLicenses.length}
              rightButton={<RightButtonPacksDetail id={obj.id} statusPacks={obj.status} statusName={showPacksDetailTable} pin={obj.pin} link={obj.link} />}
              search={showPacksDetailTable ? {
                placeholder: 'Поиск по коду номенклатуры, SKU, названию, автору',
                loading:
                loadingStatus.loadingSearch,
                data:
                search.suggestions,
                onChange: (query) =>
                  getLicensesSearchSuggestions({ query, setId: obj.id }),
                onSubmit: setLicensesSearchQuery
              } : undefined}
              detail={true}
              showPacksDetailTable={showPacksDetailTable}
              disabledExportBtn={!(tableLicenses?.length)}
              onExport={() => setModalExportOpened(true)}
            />
          </>
        </Paper>
      }
      { showPacksDetailTable &&
        <>
          <Table
            columns={columns}
            data={tableLicenses}
            onSelectionChange={(selection) => onSelectLicenses(selection)}
            onSortChange={(field, direction) => {
              setLicensesSort({ field, direction });
              setLicensesPage(1);
            }}
            expandControl="name"
            loading={loadingStatus.loading}
            triggerTableChange={triggerTableChange}
            outerSelection={selectedLicenses?.map(val => val.id) ?? []}
            defaultSortBy={initialLicensesSort.sort}
            defaultSortDirection={initialLicensesSort.direction}
          />
          <TableControls
            selectedCount={selectedLicenses.length}
            currentPage={pagination.currentPage}
            totalPages={pagination.totalPages}
            onChangePage={setLicensesPage}
            disabledPagination={disabledPagination}
          />
        </>
      }

      { obj && showPacksDetailInfo && !isPacksDetailError && <PacksDetailBody status={obj.status} jsonData={obj} />}
    </>
  );
};

export default PacksDetail;
