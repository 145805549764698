import { GetCheckEmailResponse, GetUserDetailResponse, User } from "@/types";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { PAGINATION_PER_PAGE } from '@/constants/common';

import {
  getChangeLegalEntity,
  getCheckEmail,
  getLegalEntities,
  getSendActivationLink,
  getUserDetail,
  getUsers,
  getUsersAssets,
  getUsersRoles,
  getUsersSearchSuggestions,
  patchBlockUsers,
  patchChangeRole,
  patchUnblockUsers,
  putUsers,
} from './actions';
import { ISort, IUsersFilters, UsersState } from "./types";

const initialState: UsersState = {
    //modals
    createUserModal: false,
    changeRoleModal: false,
    blockUserModal: false,
    unblockUserModal: false,
    userSuccessModal: false,

    // getUsers
    isUsersLoading: false,
    isUsersSuccess: false,
    isUsersError: false,
    // getUserDetail
    isUserDetailLoading: false,
    isUserDetailSuccess: false,
    isUserDetailError: false,

    // getUsers
    loadingUsers: false,
    loadingUsersAssets: false,
    loadingUsersSearch: false,

    isLegalEntityLoading: false,
    isLegalEntitySuccess: false,
    isLegalEntityError: false,

    isChangeLegalEntityLoading: false,
    isChangeLegalEntitySuccess: false,
    isChangeLegalEntityError: false,

    isCheckEmailLoading: false,
    isCheckEmailSuccess: false,
    isCheckEmailError: false,

    isPutUsersLoading: false,
    isPutUsersSuccess: false,
    isPutUsersError: false,

    isBlockUsersLoading: false,
    isBlockUsersSuccess: false,
    isBlockUsersError: false,

    isUnBlockUsersLoading: false,
    isUnBlockUsersSuccess: false,
    isUnBlockUsersError: false,

    isChangeRoleUsersLoading: false,
    isChangeRoleUsersSuccess: false,
    isChangeRoleUsersError: false,

    isActivationLinkLoading: false,
    isActivationLinkSuccess: false,
    isActivationLinkError: false,


    users: null,

    //Mutate users
    mutateUsers: false,

    selectedUsers: [],

    singleUser: false,

    legalEntities: [],

    changeLegalEntity: [],

    checkEmail: null,

    userDetail: null,

    // layout
    userDetailId: null,


    assets: {
        roles: [],
        region: [],
        status: [],
        createdAt: [],
        // jurPerson: [],
    },
    roles: [],
    pagination: {
        currentPage: 1,
        perPage: PAGINATION_PER_PAGE,
    },
    sort: null,
    filters: {},
    search: {
        previousQuery: undefined,
        query: undefined,
        suggestions: [],
    },

    initialUsersSort: {
        sort: 'createdAt',
        direction: 'desc',
      }
}

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        reset: () => initialState,
        setLoading(state, action: PayloadAction<boolean>) {
            state.isUsersLoading = action.payload;
        },
        setUserDetailId(state, action: PayloadAction<number | null>) {
            state.userDetailId = action.payload;
        },
        setCreateUserModal(state, action: PayloadAction<boolean>) {
            state.createUserModal = action.payload;
        },
        setChangeRoleModal(state, action: PayloadAction<boolean>) {
            state.changeRoleModal = action.payload;
        },
        setBlockUserModal(state, action: PayloadAction<boolean>) {
            state.blockUserModal = action.payload;
        },
        setUnblockUserModal(state, action: PayloadAction<boolean>) {
            state.unblockUserModal = action.payload;
        },
        setUserSuccessModal(state, action: PayloadAction<boolean>) {
            state.userSuccessModal = action.payload;
        },
        setUsersPage(state, action: PayloadAction<number>) {
            state.pagination.currentPage = action.payload;
        },
        setUsersSort(state, action: PayloadAction<ISort>) {
            state.sort = action.payload;
        },
        setUsersFilters(state, action: PayloadAction<IUsersFilters>) {
            state.filters = action.payload;
            state.pagination.currentPage = initialState.pagination.currentPage;
        },
        setUsersSearchQuery(state, action: PayloadAction<string>) {
            state.search.previousQuery = action.payload ?? state.search.previousQuery;
            state.search.query = action.payload ? action.payload : undefined;
            state.pagination.currentPage = initialState.pagination.currentPage;
        },
        setClearUsersSearchQuery(state) {
            state.search.previousQuery = undefined;
            state.search.query = undefined;
            state.pagination.currentPage = initialState.pagination.currentPage;
        },
        setMutateUsers(state, action: PayloadAction<boolean>) {
            state.mutateUsers = action.payload;
        },
        setSelectedUsers(state, action: PayloadAction<User[]>) {
            state.selectedUsers = action.payload;
        },
        setSingleUser(state, action: PayloadAction<boolean>) {
            state.singleUser = action.payload;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(getUsers.pending, (state) => {
                state.loadingUsers = true;
            })
            .addCase(getUsers.fulfilled, (state, action) => {
                state.loadingUsers = false;
                state.users = action.payload.result.items;
                state.pagination.currentPage = action.payload.result.currentPage;
                state.pagination.perPage = action.payload.result.perPage;
                state.pagination.totalItems = action.payload.result.totalItems;
                state.pagination.totalPages = action.payload.result.totalPages;
                state.initialUsersSort.sort = action.payload.result.sort;
                state.initialUsersSort.direction = action.payload.result.direction;
            })
            .addCase(getUsers.rejected, (state) => {
                state.loadingUsers = false;
            });

        builder
            .addCase(getUsersAssets.pending, (state) => {
                state.loadingUsersAssets = true;
            })
            .addCase(getUsersAssets.fulfilled, (state, action) => {
                state.loadingUsersAssets = false;
                state.assets = action.payload.result;
            })
            .addCase(getUsersAssets.rejected, (state) => {
                state.loadingUsersAssets = false;
            });

        builder
            .addCase(getUsersSearchSuggestions.pending, (state) => {
                state.loadingUsersSearch = true;
            })
            .addCase(getUsersSearchSuggestions.fulfilled, (state, action) => {
                state.loadingUsersSearch = false;
                state.search.suggestions = action.payload.result.map((item: string | number) =>
                    item.toString()
                );
            })
            .addCase(getUsersSearchSuggestions.rejected, (state) => {
                state.loadingUsersSearch = false;
            });

        builder
            .addCase(getUserDetail.pending, (state) => {
                state.isUserDetailLoading = true;
                state.isUserDetailSuccess = false;
                state.isUserDetailError = false;
                state.userDetail = null;
            })
            .addCase(getUserDetail.fulfilled, (state, action: PayloadAction<GetUserDetailResponse>) => {
                state.isUserDetailLoading = false;
                state.isUserDetailSuccess = true;
                state.userDetail = action.payload.result;
            })
            .addCase(getUserDetail.rejected, (state) => {
                state.isUserDetailLoading = false;
                state.isUserDetailError = true;
            });

        builder
            .addCase(getUsersRoles.pending, (state) => {
                state.loadingUsersAssets = true;
            })
            .addCase(getUsersRoles.fulfilled, (state, action) => {
                state.loadingUsersAssets = false;
                state.roles = action.payload.result;
            })
            .addCase(getUsersRoles.rejected, (state) => {
                state.loadingUsersAssets = false;
            });

        builder
            .addCase(getLegalEntities.pending, (state) => {
                state.isLegalEntityLoading = true;
                state.isLegalEntitySuccess = false;
                state.isLegalEntityError = false;
            })
            .addCase(getLegalEntities.fulfilled, (state, action) => {
                state.isLegalEntityLoading = false;
                state.isLegalEntitySuccess = true;
                state.legalEntities = action.payload.result;
            })
            .addCase(getLegalEntities.rejected, (state) => {
                state.isLegalEntityLoading = false;
                state.isLegalEntityError = true;
            });

        builder
            .addCase(getChangeLegalEntity.pending, (state) => {
                state.isChangeLegalEntityLoading = true;
                state.isChangeLegalEntitySuccess = false;
                state.isChangeLegalEntityError = false;
            })
            .addCase(getChangeLegalEntity.fulfilled, (state, action) => {
                state.isChangeLegalEntityLoading = false;
                state.isChangeLegalEntitySuccess = true;
                state.changeLegalEntity = action.payload.result;
            })
            .addCase(getChangeLegalEntity.rejected, (state) => {
                state.isChangeLegalEntityLoading = false;
                state.isChangeLegalEntityError = true;
            });

        builder
            .addCase(getCheckEmail.pending, (state) => {
                state.isCheckEmailLoading = true;
                state.isCheckEmailSuccess = false;
                state.isCheckEmailError = false;
            })
            .addCase(getCheckEmail.fulfilled, (state, action: PayloadAction<GetCheckEmailResponse>) => {
                state.isCheckEmailLoading = false;
                state.isCheckEmailSuccess = true;
                state.checkEmail = action.payload;
            })
            .addCase(getCheckEmail.rejected, (state) => {
                state.isCheckEmailLoading = false;
                state.isCheckEmailError = true;
            });

        builder
            .addCase(putUsers.pending, (state) => {
                state.isPutUsersLoading = true;
                state.isPutUsersSuccess = false;
                state.isPutUsersError = false;
            })
            .addCase(putUsers.fulfilled, (state, action) => {
                if (state.users) {
                  state.users = [
                    ...action.payload.result,
                    ...state.users
                  ];

                  state.pagination = {
                    ...state.pagination,
                    totalItems: state?.pagination?.totalItems ? state?.pagination?.totalItems + action.payload.result.length : 0
                  };
                }

                state.isPutUsersLoading = false;
                state.isPutUsersSuccess = true;
            })
            .addCase(putUsers.rejected, (state) => {
                state.isPutUsersLoading = false;
                state.isPutUsersError = true;
            });

            builder
            .addCase(patchBlockUsers.pending, (state) => {
                state.isBlockUsersLoading = true;
                state.isBlockUsersSuccess = false;
                state.isBlockUsersError = false;
            })
            .addCase(patchBlockUsers.fulfilled, (state) => {
                state.isBlockUsersLoading = false;
                state.isBlockUsersSuccess = true;
            })
            .addCase(patchBlockUsers.rejected, (state) => {
                state.isBlockUsersLoading = false;
                state.isBlockUsersError = true;
            });

            builder
            .addCase(patchUnblockUsers.pending, (state) => {
                state.isUnBlockUsersLoading = true;
                state.isUnBlockUsersSuccess = false;
                state.isUnBlockUsersError = false;
            })
            .addCase(patchUnblockUsers.fulfilled, (state) => {
                state.isUnBlockUsersLoading = false;
                state.isUnBlockUsersSuccess = true;
            })
            .addCase(patchUnblockUsers.rejected, (state) => {
                state.isUnBlockUsersLoading = false;
                state.isUnBlockUsersError = true;
            });

            builder
            .addCase(patchChangeRole.pending, (state) => {
                state.isChangeRoleUsersLoading = true;
                state.isChangeRoleUsersSuccess = false;
                state.isChangeRoleUsersError = false;
            })
            .addCase(patchChangeRole.fulfilled, (state) => {
                state.isChangeRoleUsersLoading = false;
                state.isChangeRoleUsersSuccess = true;
            })
            .addCase(patchChangeRole.rejected, (state) => {
                state.isChangeRoleUsersLoading = false;
                state.isChangeRoleUsersError = true;
            });

            builder
            .addCase(getSendActivationLink.pending, (state) => {
                state.isActivationLinkLoading = true;
                state.isActivationLinkSuccess = false;
                state.isActivationLinkError = false;
            })
            .addCase(getSendActivationLink.fulfilled, (state) => {
                state.isActivationLinkLoading = false;
                state.isActivationLinkSuccess = true;
            })
            .addCase(getSendActivationLink.rejected, (state) => {
                state.isActivationLinkLoading = false;
                state.isActivationLinkError = true;
            });

    },
});

export const usersActions = {
    getUsers,
    getUsersRoles,
    getUserDetail,
    getUsersAssets,
    getUsersSearchSuggestions,
    getLegalEntities,
    getChangeLegalEntity,
    getCheckEmail,
    getSendActivationLink,
    putUsers,
    patchChangeRole,
    patchBlockUsers,
    patchUnblockUsers,
    ...usersSlice.actions,
};

export default usersSlice.reducer;
