import { ApiError } from "@/types";

import {
  Dispatch,
  FC, Fragment,
  ReactNode,
  SetStateAction,
  useEffect, useRef,
  useState
} from "react";
import { useNavigate } from "react-router-dom";
import {
    ActionIcon,
    Anchor,
    Box,
    Button,
    Checkbox,
    Container,
    FileButton,
    Group,
    Modal,
    NumberInput,
    Radio,
    Text,
    Textarea,
    TextInput,
    Title,
} from "@mantine/core";
import { v4 as uuidv4 } from 'uuid';
import { read } from 'xlsx'

import { COLORS } from "@/constants/mantine/colors";
import { AppPath } from "@/routes/routes-enum";

import Table, { ErrorMessages } from "@/ui/Table/Table";

import { ReactComponent as ErrorCircle } from '../../../assets/icons/ErrorCircle.svg';

import EmailValidator from "./EmailValidator";
import { useStyles } from './styles';

import { ReactComponent as Clear } from '@/assets/icons/Clear.svg';
import { useActions, useTypedSelector } from "@/store/hooks";
import { ILicense } from "@/store/licenses/types";
import { ILicensesTemplate, Organizations } from "@/store/packs/types";
import { IProduct } from "@/store/products/types";

interface ICreateLicensePack {
    createLicensePack: boolean;
    setCreateLicensePack: Dispatch<SetStateAction<boolean>>;
}

export interface EmailItem {
    id: string;
    text: string;
    error: boolean;
    duplicate: boolean;
    responseError: ApiError | null;
    message: ReactNode;
    onBlur: boolean;
    organizations: Organizations[];
}

const CreateLicensePack: FC<ICreateLicensePack> = ({ createLicensePack, setCreateLicensePack }) => {
    const resetRef = useRef<() => void>(null);
    const {
        postLicensesTemplate,
        postPacksSchoolLicenses,
        setLicenses,
        setTriggerTableChange,
        setDistrLicenses,
        setDisableCreateLicense,
        setShowPacksFormed,
        setShowMultiPacksFormed,
        resetPackLoading,
        setTemplateLicenses,
        setComplectName,
        addLicenseValues,
        delLicenseValues,
        setLicnseValues,
        setSelectedUsers,
        setLicenseFromUser,
        resetPacksTemplate,
        resetPacksLicensesTemplate,
        setPacksLicensesTemplate,
        setUpdateLicenses,
        setShowMultiPacksSchoolFormed
    } = useActions();

    const { statusProducts } = useTypedSelector((store) => store.products);
    const { licenses, updateLicenses } = useTypedSelector((store) => store.licenses);

    const {
        distrLicensesPack,
        packsTemplate,
        packsLicensesTemplate,
        packsActivation,
        triggerTableChange,
        disableCreateLicense,
        isPacksLicensesLoading,
        isPacksLicensesSuccess,
        complectName,
        licensesValues,
        licenseFromUser,
        fetchLicenseValues,
        error,
        isPacksLicensesError
    } = useTypedSelector((store) => store.packs);
    const { selectedUsers } = useTypedSelector((store) => store.users)
    const navigate = useNavigate();
    const { classes } = useStyles();
    const [multiUsers, setMultiUsers] = useState<boolean>(false);
    const [noEmail, setNoEmail] = useState<boolean>(false);
    const [templateFileError, setTemplateFileError] = useState<boolean>(false);
    const [templateLoad, setTemplateLoad] = useState<boolean>(false);
    const [issueLicense, setIssueLicense] = useState<boolean>(false);
    const [emailList, setEmailList] = useState<EmailItem[]>([{ id: uuidv4(), text: '', error: false, duplicate: false, responseError: null, message: null, onBlur: false, organizations: [] }]);
    const [payMode, setPayMode] = useState('paid');
    const [outMode, setOutMode] = useState('manual');
    const [file, setFile] = useState<File | null>(null);
    const [comment, setComment] = useState('');
    const [errorMessages, setErrorMessages] = useState<ErrorMessages>([]);
    const [errorTemplateMessages, setErrorTemplateMessages] = useState<ErrorMessages>([]);
    const commonErrorMessages = outMode === 'manual' ? errorMessages : errorTemplateMessages;
    const [volumeTotal, setVolumeTotal] = useState(1);

    const disableCreateLicenseButton = () => {
        if (noEmail) return false;
        return selectedUsers.length ? false : disableCreateLicense;
    };

    const setAllErrorMessages = (arr: ErrorMessages) => {
        if (outMode === 'manual') setErrorMessages(() => arr)
        else setErrorTemplateMessages(() => arr)
    }

    const navigateFromUser = AppPath.licenses;

    const checkCols = ((value: string) => (/^A1:B\d{1,7}$/g.test(value)))
    const readExcelFile = (f: File) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(f);
        fileReader.onload = (e) => {
            setTemplateFileError(true);
            const bufferArray = e.target?.result;
            const wb = read(bufferArray, { type: 'binary' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const colsData = ws["!ref"];

            if (!colsData) {
                setTemplateFileError(true);
                return;
            }

            const cols = checkCols(colsData);

            if (!cols) {
                setTemplateFileError(true);
                return;
            }

            const columnNM = Object.entries(ws).filter((vol) => vol[0].startsWith('A'));
            const listNM = columnNM.map((vol) => Object.values(vol[1])[1]);
            const columnVolumeNM = Object.entries(ws).filter((vol) => vol[0].startsWith('B'));
            const listVolumeNM = columnVolumeNM.map((vol) => Object.values(vol[1])[1]);

            if (listNM.length > 0) {
                const templateValues = listNM.map((val, i) => ({ id: val as string, value: listVolumeNM[i] as number }));
                setLicnseValues({ licenses: templateValues.map(val => ({ id: val.id, value: val.value.toString() })), outMode });

                setTemplateFileError(false)
                setTemplateLoad(true)
                postLicensesTemplate({ template: file })
            } else {
              setTemplateFileError(true)
            }
        }

        setFile(null);
        resetRef.current?.();
    };

    const emailToCreate: string[] = [];
    emailList.forEach((val) => { emailToCreate.push(val.text) });
    const emailArrayLength = emailToCreate.filter((el) => el !== '').length;

    let licensesPackData: IProduct[] | ILicense[] | ILicensesTemplate[];

    const onRemoveLicensePack = (id: number) => {
       if (distrLicensesPack.length) {
        setDistrLicenses(distrLicensesPack.filter(val => val.id !== id));
      } else if (outMode === 'template') {
         setPacksLicensesTemplate(packsLicensesTemplate.licenses.filter(val => val.id !== id));
      }

      setTriggerTableChange(!triggerTableChange);
    }

    if (outMode === 'template') {
         licensesPackData = packsLicensesTemplate.licenses;
    } else {
        licensesPackData = distrLicensesPack;
    }

    const commonLicenseValues = outMode === 'template' ? fetchLicenseValues : licensesValues;

    const onResetAllVolume = () => {
        setLicenses([]);
        setDistrLicenses([]);
        setTemplateLicenses([]);
        setTriggerTableChange(!triggerTableChange);
    }

    const checkErrors = () => {
        let isError = false;
        commonErrorMessages.forEach((val) => { isError = isError || !!val.message });

        return isError;
    }

    const single = outMode !== 'template' && (licensesPackData.length === 1 || licensesPackData.length === 0);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const tableLicensesPack = licensesPackData?.map(( {volume, ...item} ) => ({
        volume: (
            <Text>
                <Box className={classes.boxInput}>
                    <ActionIcon ml={10} variant="transparent" onClick={() => onRemoveLicensePack(item.id)}>
                        <Clear />
                    </ActionIcon>
                </Box>
            </Text>
        ),
        ...item,
    }));

    const columns = [
        { id: 'externalCode', text: 'Номенклатура', width: 103 },
        { id: 'name', text: 'Название', width: 280 },
        { id: 'author', text: 'Автор', width: 146 },
        { id: 'volume', text: '', width: 90 },
    ];

    const clearState = () => {
        setEmailList(() => [{ id: uuidv4(), text: '', error: false, duplicate: false, responseError: null, onBlur: false, organizations: [], message: '' }]);
        setPayMode('paid');
        setOutMode('manual');
        setMultiUsers(false);
        setNoEmail(false);
        setComplectName('');
    };

    useEffect(() => {
        if (licensesPackData.length > commonLicenseValues.length) {
            if (!commonLicenseValues.length) {
                licensesPackData.forEach(val =>
                    addLicenseValues({ id: val.id.toString(), value: '1', outMode })
                );
                return;
            }
            const targetEl = (licensesPackData as ILicense[]).find(val => !commonLicenseValues.find(lVal => lVal.id === val.id.toString()))
            if (targetEl) {
              addLicenseValues({
                id: targetEl.id.toString(),
                value: '1',
                outMode
              });
            }
        } else if (licensesPackData.length < commonLicenseValues.length) {
            if (!licensesPackData.length) {
                setLicnseValues({ licenses: [], outMode });
                return;
            }
            const targetElIndex = commonLicenseValues.find(val => !(licensesPackData as ILicense[]).find(lVal => lVal.id.toString() === val.id))?.id
            if (targetElIndex) {
                delLicenseValues({ id: targetElIndex, outMode });
            }
        }
    }, [licensesPackData, emailArrayLength])

    useEffect(() => {
        if (issueLicense) {
              if (!licenses) return;

              let choosesLicense: { license_id: number; volume: number; }[] = [];

              if (outMode === 'template') {
                  (licensesPackData as ILicensesTemplate[]).forEach(val => {
                      choosesLicense.push({ license_id: Number(val.id), volume: Number(val.volume) });
                      resetPacksTemplate();
                      resetPacksLicensesTemplate();
                  });
              } else {
                  (licensesPackData as ILicense[]).forEach(val => {
                    let numberVal: number = multiUsers ? emailList.length : volumeTotal;

                    choosesLicense.push({ license_id: Number(val.id), volume: Number(numberVal) });
                  });
              }

              postPacksSchoolLicenses({
                  name: complectName,
                  comment: comment,
                  emails: emailToCreate[0] === "" ? [] : emailToCreate,
                  licenses: choosesLicense,
                  payable: payMode === 'paid',
              });
        }

      setIssueLicense(false);
    }, [issueLicense]);

    useEffect(() => {
        if (file) {
            readExcelFile(file);
        }
    }, [file]);

    useEffect(() => {
        if (isPacksLicensesLoading) return;

        if (isPacksLicensesSuccess) {
            if (multiUsers) {
              setShowMultiPacksFormed(true)
            } else if (noEmail) {
                setShowMultiPacksSchoolFormed(true)
            } else {
              setShowPacksFormed(true)
            }
            setDisableCreateLicense(true);
            setCreateLicensePack(false);
            resetPackLoading();
            setOutMode('manual');
            clearState();
            onResetAllVolume();

            if (!updateLicenses) {
                setUpdateLicenses(true)
            }
        }

        return () => {
            setUpdateLicenses(false)
        }
    }, [isPacksLicensesLoading]);

    useEffect(() => {
        setAllErrorMessages(licensesPackData.map(val => ({ id: val.id, message: "" })))
    }, [licensesPackData])

    useEffect(() => {
        setShowPacksFormed(false);
        setShowMultiPacksFormed(false);
        if (selectedUsers.length) {
            setEmailList((prevState) => {
                let newState = prevState.filter(val => val.text && !val.error && !val.responseError).filter(val => !val.id.includes('emailUsers'));
                newState = [...newState, ...selectedUsers.map(val =>
                    ({ id: `${val.id}_emailUsers`, text: val.email ?? "", error: false, duplicate: false, responseError: null, message: null, onBlur: false, organizations: val.organization ?? [] })
                )]
                return newState;
            });
        }
        if (selectedUsers.length > 1) {
            setMultiUsers(() => true);
        }
    }, []);

    return (
        <Modal size={934} opened={createLicensePack} onClose={() => {setCreateLicensePack(false)}}>
          <Title className={classes.textTitleBlockModal} ta={'center'}>
            { single ? 'Выдача лицензии' : 'Создание комплекта лицензий' }
          </Title>

          <Container mt={48} p={0}>
            <Group className={classes.groupPackName}>
              <TextInput
                label={single ? 'Название лицензии' : 'Название комплекта'}
                required
                w={577}
                placeholder={single ? 'Введите название лицензии' : 'Введите название комплекта'}
                styles={{
                  input: {
                    textOverflow: 'ellipsis',
                  }
                }}
                value={complectName}
                onChange={(event) => setComplectName(event.currentTarget.value)}
                onBlur={() => setComplectName(complectName.trim())}
              />
            </Group>

            <Group className={classes.selectRole}>
              <Checkbox
                mt={16}
                disabled={!!selectedUsers.length && licenseFromUser}
                label='Назначить на нескольких пользователей'
                checked={multiUsers}
                onChange={(event) => setMultiUsers(event.currentTarget.checked)}
              />
            </Group>
          </Container>

          <EmailValidator
            single={single}
            multiUsers={multiUsers}
            roleId={'ROLE_USER'}
            emailList={emailList}
            setEmailList={setEmailList}
            chooseRoleAMSU={true}
            noEmail={noEmail}
            setNoEmail={setNoEmail}
          />

          <Container mt={48} p={0}>
            <Text className={classes.textBlockTitle}>
              Способ выдачи <span style={{fontSize: 16, color: COLORS.RED.text}}>*</span>
            </Text>
            <Radio.Group mt={16} value={outMode} onChange={setOutMode} withAsterisk>
              <Group className={classes.groupRadio}>
                <Radio value="manual" label="Вручную"/>
                <Radio value="template" label="По шаблону" disabled={true}/>
              </Group>
            </Radio.Group>
          </Container>

          <Container mt={48} p={0}>
            <Text className={classes.textBlockTitle}>
              Состав комплекта <span style={{ fontSize: 16, color: COLORS.RED.text }} >*</span>
            </Text>
          </Container>

            <Container mt={24} p={0}>
                {
                    licensesPackData.length > 0 ?
                        <>
                            <Box>
                              {noEmail &&
                                <Group style={{fontSize: 14, fontWeight: 400, lineHeight: '135%'}} mb={16}>
                                  <span className={classes.selectedTitle}>Количество лицензий:</span>
                                  <NumberInput
                                    stepHoldDelay={500}
                                    stepHoldInterval={(t) => Math.max(1000 / t ** 2, 25)}
                                    placeholder={'1'}
                                    defaultValue={1}
                                    value={volumeTotal}
                                    min={1}
                                    max={100}
                                    parser={(value) => {
                                        const val = value ? parseInt(value, 10) : 0;

                                        return val >= 100 ? "100" : value;
                                    }}
                                    onChange={(current) => {
                                      setVolumeTotal(current ?? 1)
                                    }}
                                  />
                                </Group>}

                                <Group style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Group style={{ fontSize: 14, fontWeight: 400, lineHeight: '135%' }}>
                                        <span className={classes.selectedTitle}>Выбрано:</span>
                                        {((outMode !== 'template' && licensesPackData.length) || licensesPackData.length) ? licensesPackData.length : null}
                                    </Group>
                                    { ((outMode !== 'template')) && <Anchor className={classes.anchor} onClick={() => {
                                        onResetAllVolume();
                                        if (licensesPackData.length || ((outMode === 'template') && !licensesPackData.length)) return;
                                        setCreateLicensePack(false);
                                    }}>Удалить всё</Anchor> }
                                </Group>
                            </Box>

                            <Box h={398} mt={16} mb={32} className={classes.boxTable}>
                                <Table key={outMode} columns={columns} data={tableLicensesPack} loading={statusProducts.isProductsLoading} errorMessages={commonErrorMessages} />
                            </Box>
                        </>
                        : null
                }

              { (outMode === 'template') &&
                <Text color={COLORS.RED.text} size={12} mt={8}>
                  { !!packsTemplate.message &&
                    <span>
                      <ErrorCircle style={{marginRight: 4}}/>
                      { packsTemplate.message }
                    </span>
                  }

                  { !!packsLicensesTemplate.message &&
                    <span>
                      <ErrorCircle style={{marginRight: 4}}/>
                      { packsLicensesTemplate.message }
                    </span>
                  }
                </Text> }
            </Container>

            <Container p={0}>
                { outMode !== 'template' ? outMode !== 'pricelist' &&
                    <Button w={398} mt={0} className={classes.modalButton} onClick={() => {
                            navigate(navigateFromUser);
                            setTemplateLoad(false);
                            setCreateLicensePack(false)
                        }}>Добавить продукт</Button>
                    :
                    <Fragment>
                        <FileButton
                            onChange={(f: File) => {
                                resetPacksTemplate();
                                resetPacksLicensesTemplate();
                                setFile(f);
                            }}
                            resetRef={resetRef}
                            accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                        >
                            {(props) =>
                                <Button
                                    variant={templateLoad ? 'default' : 'filled'}
                                    w={398}
                                    className={classes.modalButton}
                                    {...props}
                                >
                                    {templateLoad ? 'Загрузить новый шаблон' : 'Загрузить шаблон'}
                                </Button>
                            }
                        </FileButton>
                        {
                            templateFileError &&
                            <Text color={COLORS.RED.text} size={12} mt={16} w={398}>
                                <span>
                                  <ErrorCircle style={{ marginRight: 4 }} />
                                    Не удалось прочитать файл. Пожалуйста, убедитесь, что файл имеет верную структуру.
                                </span>
                            </Text>
                        }
                    </Fragment>
                }
                <Textarea
                    mt={48}
                    minRows={6}
                    placeholder='Комментарий...'
                    label='Комментарий'
                    w={'auto'}
                    value={comment}
                    onChange={(event) => setComment(event.currentTarget.value)}
                />

                { (error?.response?.data?.error && isPacksLicensesError) && <Text color={COLORS.RED.text} size={12} mt={8}>
                    <span><ErrorCircle style={{marginRight: 4}}/>{ error.response.data.error }</span>
                </Text> }
            </Container>

            <Container className={classes.modalButtonContainer}>
                <Button
                    disabled={
                        (!licensesPackData.length && !packsActivation.length)
                        || !complectName
                        || disableCreateLicenseButton()
                        || checkErrors()
                        || isPacksLicensesLoading
                    }
                    w={398}
                    className={classes.modalButton}
                    onClick={() => { setIssueLicense(true) }}
                >{single ? 'Выдать лицензию' : 'Создать комплект'}
                </Button>
                <Button
                    w={398}
                    className={classes.modalButton}
                    c={COLORS.RED.text}
                    variant="outline"
                    onClick={() => {
                        resetPacksTemplate();
                        resetPacksLicensesTemplate();
                        clearState();
                        setOutMode('manual');
                        setLicenseFromUser(false);
                        setSelectedUsers([]);
                        onResetAllVolume();
                        setTriggerTableChange(!triggerTableChange);
                    }}
                >Отменить
                </Button>
            </Container>
        </Modal>
    );
};

export default CreateLicensePack;
