import { AxiosError } from 'axios';

import { errorHandler } from './errorHandler';

export const axiosErrorHandler = (error: AxiosError<{ message: string, error?: string }>, fallbackMsg?: string) => {
  const message =
      error.response?.data?.message ||
      error.response?.data?.error ||
      error.response?.statusText ||
      error.message ||
      fallbackMsg ||
      'Неизвестная ошибка';

  if (message) errorHandler(error, message);
};
