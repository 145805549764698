import { FC, ReactNode } from "react";
import { Box, Text } from "@mantine/core";

import { useStyles } from "./styles";

interface Props {
    children?: ReactNode;
    title?: string;
}

const UserColumnWrapper: FC<Props> = ({ title, children }) => {
    const { classes } = useStyles();
    return (
        <Box className={classes.Box}>
            <Text className={classes.Title}>
                { title }
            </Text>
            { children }
        </Box>
    )
}

export default UserColumnWrapper;
