import { AuthorizeParams, AuthorizeResponse, PostAuthUrlParams, PostAuthUrlResponse } from '@/types';

import axiosInstance from '../instance';
import { URLS } from '../urls';

export const authApi = {

  postAuthUrl(params: PostAuthUrlParams) {
    return axiosInstance.request<PostAuthUrlResponse>({
      method: 'post',
      url: URLS.auth.postAuthUrl,
      data: {
        redirectLink: params.redirectLink
      }
    });
  },

  getAuthUrl() {
    return axiosInstance.request({
      method: 'get',
      url: URLS.auth.getAuthUrl,
    });
  },

  authorize(params: AuthorizeParams) {

    return axiosInstance.request<AuthorizeResponse>({
      method: 'get',
      url: URLS.auth.oauth,
      params,
    });
  },

  logout() {
    return axiosInstance.request({
      method: 'get',
      url: URLS.auth.logout,
    });
  },
};
