import { createStyles } from '@mantine/core';

import { COLORS } from '@/constants/mantine/colors';

export const useStyles = createStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',

    '& .mantine-Button-root': {
      paddingLeft: 15,
      paddingRight: 15,
      minWidth: 174,
    },
  },

  selectedTitle: {
    color: COLORS.GRAY.text,
  },
}));
