import axios from 'axios';
import qs from 'qs';

import { URLS } from './urls';

import {logout} from "@/store/auth/actions";

let store: any;
export const injectStore = (storeLink: any) => store = storeLink;

const axiosInstance = axios.create({
  baseURL: process.env.PUBLIC_API_URL,
  headers: {
    Accept: "application/json"
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { indices: false });
  },
});

export const objectToJson = (obj: Record<string, unknown>): string => {
  return JSON.stringify(obj, null, 0);
};

axiosInstance.interceptors.request.use(
  (request) => {
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401 &&
      location.href !== URLS.auth.oauth &&
      !error.response.data.badOrganization
    ) {
      if (!error.response.data.url) {
        store?.dispatch(logout());
      } else {
        window.location.href = error.response.data.url;
      }

      return;
    }

    if (error.response.status === 302) {
      window.location.href = error.response.data.url as string;
      return;
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
